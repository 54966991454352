import { createRouter, createWebHashHistory } from "vue-router";
import { useAuth } from "@/stores";

import App from "./App.vue";

const routes = [
	{
		path: "/",
		name: "app",
		component: App,
		children: [
			{
				path: "",
				name: "dashboard",
				component: () => import("./components/Dashboard.vue"),
			},
			{
				path: "/dosificaciones",
				name: "dosificaciones",
				component: () => import("./module/dosificaciones/DosificacionList.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("Dosificacion Listar" in auth.user.permissions) return true;
					return false;
				} */
			},
			{
				path: "/configuracion/empresa",
				name: "mi_empresa",
				component: () => import("./module/empresa/EmpresaEdit.vue"),
				beforeEnter: () => { //RUTA PARA EDITAR EMPRESA
					let auth = useAuth();
					if ("Sucursal Empresa" in auth.user.permissions) return true;
					return false;
				}
			},
			{
				path: "/punto_venta",
				name: "punto_venta",
				component: () => import("./module/puntoventas/PuntoVentaList.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("Punto de Venta" in auth.user.permissions) return true;
					return false;
				} */
			},
			{
				path: "/sucursales",
				name: "sucursales",
				component: () => import("./module/sucursales/SucursalList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Sucursal Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/sucursal/:id/detalle",
				name: "sucursaldetalle",
				component: () => import("./module/sucursales/SucursalDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Sucursal Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},
			//RUTAS PARA UNIDAD DE MEDIDAS
			{
				path: "/unidadmedidas",
				name: "unidadmedidas",
				component: () => import("./module/unidadmedida/UnidadmedidaList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Unidad de Medida Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			//RUTAS PARA PROVEEDORES
			{
				path: "/proveedores",
				name: "proveedores",
				component: () => import("./module/proveedores/ProveedorList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Proveedor Crear" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/proveedorpagos",
				name: "proveedorpagos",
				component: () => import("./module/proveedores/ProveedorPagoList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Proveedor Realizar Pagos" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/pagos_proveedor/:id/detalle",
				name: "pagos_proveedor_detalle",
				component: () => import("./module/proveedores/PagosProveedorDetalle.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("Proveedor Pago Ver Detalle" in auth.user.permissions) return true;
					return false;
				}, */

			},
			//RUTAS PARA Usuarios
			{
				path: "/users",
				name: "users",
				component: () => import("./module/users/UserList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Usuario Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/medicos",
				name: "medicos",
				component: () => import("./module/medicos/MedicoList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Medico Listar" in auth.user.permissions) return true;
					return false;
				}
			},
			{
				path: "/usuario-detalle",
				name: "usuario-detalle",
				component: () => import("./module/users/UserDetalle.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("Usuario Ver Detalle" in auth.user.permissions) return true;
					return false;
				}, */
			},
			{
				path: "/proveedor/:id/detalle",
				name: "proveedordetalle",
				component: () => import("./module/proveedores/ProveedorDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Proveedor Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/productos",
				name: "productos",
				component: () => import("./module/productos/ProductosList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Producto Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/grupos",
				name: "grupos",
				component: () => import("./module/grupos/GrupoList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Grupo Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/subgrupos",
				name: "subgrupos",
				component: () => import("./module/subgrupos/SubgrupoList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("SubGrupo Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/procedencias",
				name: "procedencias",
				component: () => import("./module/procedencias/ProcedenciaList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Procedencia Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/paqueteproductos",
				name: "paqueteproductos",
				component: () => import("./module/paqueteproductos/PaqueteProductosList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("PaqueteProducto Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/producto/:id/detalle",
				name: "ProductoDetalle",
				component: () => import("./module/productos/ProductoDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Producto Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},

			// RUTA PARA FECHA VENCE PRODUCTO
			{
				path: "/fechavence",
				name: "fechavence",
				component: () => import("./module/reportes/FechaVence.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_FechaVence de FechaVence" in auth.user.permissions)
						return true;
					return false;
				}
			},
			{
				path: "/actualizarfechavence/:id/detalle",
				name: "actualizarfechavence",
				component: () => import("./module/reportes/ActualizarFechaVence.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_FechaVence Actualizar" in auth.user.permissions)
						return true;
					return false;
				}
			},
			{
				path: "/laboratorios",
				name: "laboratorios",
				component: () => import("./module/laboratorios/Laboratorio.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Laboratorio Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/laboratorio/:id/detalle",
				name: "laboratorio-detalle",
				component: () => import("./module/laboratorios/LaboratorioDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Laboratorio Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},

			//RUTA PARA CAJAS
			{
				path: "/caja",
				name: "caja",
				component: () => import("./module/cajas/CajaList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Caja Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "caja/:id/detalle",
				name: "caja-detalle",
				component: () => import("./module/cajas/CajaDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Caja Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/mov_caja",
				name: "mov_caja",
				component: () => import("./module/cajas/MovCaja.vue"),
			},
			{
				path: "/compras",
				name: "compras",
				component: () => import("./module/compras/index.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Compra Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/orden_compra/:id/detalle",
				name: "orden_compra",
				component: () => import("./module/compras/OcDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Compra Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/oc/:id/recibir",
				name: "oc_recepcion",
				component: () => import("./module/compras/ComprasRecibir.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Compra Recibir" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/oc/:id/detalle_recepcion",
				name: "detalle_recepcion",
				component: () => import("./module/compras/ComprasRecepcionDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Compra Ver DetalleRecepcion" in auth.user.permissions)
						return true;
					return false;
				},
			},
			{
				path: "/nueva_venta",
				name: "nueva_venta",
				component: () => import("./module/ventas/VentasCrear.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Venta Crear" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/ventas",
				name: "ventas",
				component: () => import("./module/ventas/VentasList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Venta Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/ventas/:id/detalle",
				name: "venta_detalle",
				component: () => import("./module/ventas/VentasDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Venta Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/proformas",
				name: "proformas",
				component: () => import("./module/proformas/ProformaList.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("Proforma Listar" in auth.user.permissions) return true;
					return false;
				}, */
			},
			{
				path: "/proforma/:id/detalle",
				name: "proforma_detalle",
				component: () => import("./module/proformas/ProformaDetalle.vue"),

			},
			{
				path: "/reportes/ventas",
				name: "reporte_ventas",
				component: () => import("./module/reportes/Ventas.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Ventas de Ventas" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/reportes/ventas/ganancias",
				name: "reporte_ganancias",
				component: () => import("./module/reportes/VentaGanancias.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Ganancias de Ganancias" in auth.user.permissions) return true;
					return false;
				}
			},
			/* =================================
			RUTAS PARA FACTURACION ELECTRONICA
			===================================== */
			{
				path: "/filtros_facturas",
				name: "filtros_facturas",
				component: () => import("./module/facturas/FiltrosFacturas.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("R_Facturacion Listar" in auth.user.permissions) return true;
					return false;
				}, */
			},
			{
				path: "/reportes/stocks",
				name: "reporte_stocks",
				component: () => import("./module/reportes/Stocks.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Stock de Stock" in auth.user.permissions) return true;
					return false;
				},
			},

			//RUTA PARA REPORTES PRODUCTOS
			{
				path: "/reportes/productos",
				name: "reporte_productos",
				component: () => import("./module/reportes/Productos.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Productos de Productos" in auth.user.permissions) return true;
					return false;
				},
			},

			//RUTA PARA REPORTES COMPRAS
			{
				path: "/reportes/compras",
				name: "reporte_compras",
				component: () => import("./module/reportes/Compras.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Compras de Compras" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/reportes/inversionnegocio",
				name: "reporte_inversionnegocio",
				component: () => import("./module/reportes/InversionNegocio.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Inversion de Inversion" in auth.user.permissions) return true;
					return false;
				},
			},

			//RUTA PARA REPORTES CLIENTES
			{
				path: "/reportes/clientes",
				name: "reporte_clientes",
				component: () => import("./module/reportes/Clientes.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Clientes de Clientes" in auth.user.permissions) return true;
					return false;
				},
			},

			//RUTA PARA REPORTES DE CAJAS
			{
				path: "/reportes/cajas",
				name: "reporte_cajas",
				component: () => import("./module/reportes/Cajas.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("R_Cajas de Cajas" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/cliente_ventas/:id/detalle",
				name: "reporte_clientes_detalle",
				component: () => import("./module/reportes/ClientesDetalle.vue"),
			},
			//RUTA PARA CLIENTES
			{
				path: "/clientes",
				name: "clientes",
				component: () => import("./module/clientes/ClienteList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Cliente Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/cliente/:id/detalle",
				name: "clientedetalle",
				component: () => import("./module/clientes/ClienteDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Cliente Ver Detalle" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/clientespagos",
				name: "clientespagos",
				component: () => import("./module/clientes/ClientePagoList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Cliente Pagos" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/pago_clientes/:id/detalle",
				name: "pago_clientes_detalle",
				component: () => import("./module/clientes/PagosClienteDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Cliente DetallePagos" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/grupo_desc_cliente",
				name: "grupo_desc_cliente",
				component: () => import("./module/grupo_desc_cliente/GrupoDescList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("GrupoDesCliente Listar" in auth.user.permissions) return true;
					return false;
				}
			},
			{
				path: "/grupo_desc_cliente/:id/detalle",
				name: "grupo_desc_cliente_detalle",
				component: () => import("./module/grupo_desc_cliente/GrupoDescClientes.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("GrupoDesCliente Listar" in auth.user.permissions) return true;
					return false;
				}
			},
			//RUTA PARA ROLES
			{
				path: "/roles",
				name: "roles",
				component: () => import("./module/roles/RolList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Rol Listar" in auth.user.permissions) return true;
					return false;
				},
			},
			//RUTA PARA ROLES
			{
				path: "/permisos",
				name: "permisos",
				component: () => import("./module/roles/PermissionsForm.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Permiso Listar" in auth.user.permissions) return true;
					return false;
				},
			},

			//INVENTARIO
			{
				path: "/inventario/carga_inicial",
				name: "inventario_carga_inicial",
				component: () =>
					import("./module/inventarios/InventarioCargaInicial.vue"),
			},
			{
				path: "movimientos",
				name: "movimientos",
				component: () => import("./module/movimientos/Movimientos.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Inventario Movimientos" in auth.user.permissions) return true;
					return false;
				},
			},
			{
				path: "/MovAlmacenList",
				name: "movimientos_almacen",
				component: () =>
					import("./module/movimientos/MovAlmacenList.vue"),
				/* beforeEnter: () => {
					let auth = useAuth();
					if ("Inventario MovimientosAlmacen" in auth.user.permissions)
						return true;
					return false;
				}, */
			},
			{
				path: "/mov_almacen/:id/detalle",
				name: "mov_almacen",
				component: () => import("./module/movimientos/MovAlmacenDetalle.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Inventario Movimientos" in auth.user.permissions) return true;
					return false;
				},
			},

			// RUTA PARA KARDEX
			{
				path: "/kardex",
				name: "kardex",
				component: () => import("./module/kardex/Kardex.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Inventario KardexProductos" in auth.user.permissions)
						return true;
					return false;
				},
			},

			// RUTA PARA AJUSTE INVENTARIO
			{
				path: "/ajuste_inventario",
				name: "ajuste_inventario",
				component: () => import("./module/inventarios/AjusteInventario.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Inventario AjusteInventario" in auth.user.permissions)
						return true;
					return false;
				},
			},

			//RUTA PARA BITACORAS
			{
				path: "/bitacoras",
				name: "listado_bitacoras",
				component: () => import("./module/bitacoras/BitacoraList.vue"),
				beforeEnter: () => {
					let auth = useAuth();
					if ("Bitacoras Listar" in auth.user.permissions) return true;
					return false;
				}
			},
			{
				path: "/parametros",
				name: "parametros",
				component: () => import("./module/parametros/Parametros.vue"),

			},
			{
				path: "responsables/listado",
				name: "responsables_asignar_listado",
				component: () => import("./module/responsables/ResponsableAsignarListado.vue"),
			},
			{
				path: "responsables/editar/:id/permisos",
				name: "ResponsableAsignar",
				component: () => import("./module/responsables/ResponsableAsignarEditar.vue"),
			}
		],
	},
	{
		path: "/login",
		name: "login",
		component: () => import("./pages/Login.vue"),
	},
	{
		path: "/landing",
		name: "landing",
		component: () => import("./pages/LandingDemo.vue"),
	},
	{
		path: "/error",
		name: "error",
		component: () => import("./pages/Error.vue"),
	},
	{
		path: "/notfound",
		name: "notfound",
		component: () => import("./pages/NotFound.vue"),
	},
	{
		path: "/access",
		name: "access",
		component: () => import("./pages/Access.vue"),
	},
	{ path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach(async (to) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ["/login"];
	const authRequired = !publicPages.includes(to.path);
	const auth = useAuth();

	if (authRequired && !auth.user) {
		auth.returnUrl = to.fullPath;
		return "/login";
	}
});

export default router;
