import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class EmpresaService {
  async getFirstEmpresa() {
    const empresa = await fetchWrapper.get(`${ruta}/empresa`);
    return empresa;
  }

  async updateEmpresaLogo(empresa_id, empresa) {
    const empresaActualizada = await fetchWrapper.postFiles(`${ruta}/empresa_logo/` + empresa_id, empresa);
    return empresaActualizada;
  }

  async updateEmpresa(empresa_id, empresa) {
    const empresaActualizada = await fetchWrapper.put(`${ruta}/empresa/` + empresa_id, empresa);
    return empresaActualizada;
  }

  async sincronizarSIN(){
    return await fetchWrapper.post(`${ruta}/sincronizar_empresa`);
  }

  async getActividades(){
    return await fetchWrapper.get(`${ruta}/actividades`);
  }
  async changeActividad(datos){
    return await fetchWrapper.post(`${ruta}/change_actividad`, datos);
  }
}